import styled, { css } from "styled-components";
import {
  SiTwitter,
  SiFacebook,
  SiInstagram,
  SiPinterest,
} from "react-icons/si";

const socialMediaIcons = css`
  width: 40px;
  height: 40px;
  border: 0.5px solid white;
  padding: 5px;
  fill: white;
  cursor: pointer;
`;

export const TwitterIcon = styled(SiTwitter)`
  ${socialMediaIcons}
`;

export const InstagramIcon = styled(SiInstagram)`
  ${socialMediaIcons}
`;

export const PinterestIcon = styled(SiPinterest)`
  ${socialMediaIcons}
`;

export const FacebookIcon = styled(SiFacebook)`
  ${socialMediaIcons}
`;
