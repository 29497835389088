import React from "react";

import {
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  InstagramIcon,
} from "./SocialMedia.elements";

const Facebook = () => <FacebookIcon />;
const Twitter = () => <TwitterIcon />;
const Pinterest = () => <PinterestIcon />;
const Instagram = () => <InstagramIcon />;

export { Facebook, Twitter, Pinterest, Instagram };
