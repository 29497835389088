import React from "react";
import { LinkWrapper, Link } from "./CustomLink.elements";

const CustomLink = ({ label, to, isExact }) => {
  return (
    <LinkWrapper>
      <Link to={to} exact={isExact}>
        {label}
      </Link>
    </LinkWrapper>
  );
};

export default CustomLink;
