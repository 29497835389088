import React, { useState, useEffect } from "react";
import {
  HeaderContainer,
  HeaderTop,
  DataWrapper,
  IconsWrapper,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  InstagramIcon,
  InfoWrapper,
  GmailIcon,
  TelIcon,
  Span,
  NavBar,
  TimesIcon,
  NavBarItems,
} from "./Header.elements";

import CustomNav from "../Nav/Nav";

const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [headerPosition, setHeaderPosition] = useState(false);

  const handleShow = (e) => {
    setShowNavbar(true);
  };

  const handleHide = (e) => {
    setShowNavbar(false);
  };

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 39) {
        setHeaderPosition(true);
      } else {
        setHeaderPosition(false);
      }
    };
  }, []);

  return (
    <>
      {showNavbar && (
        <NavBar showNavbar onClick={handleHide}>
          <TimesIcon onClick={handleHide} />
          <NavBarItems exact to='/' onClick={handleHide}>
            Home
          </NavBarItems>
          <NavBarItems to='/about' onClick={handleHide}>
            About
          </NavBarItems>
          <NavBarItems to='/services' onClick={handleHide}>
            Services
          </NavBarItems>
          <NavBarItems to='/customer-projects' onClick={handleHide}>
            Customer Projects
          </NavBarItems>
          <NavBarItems to='/contact' onClick={handleHide}>
            Contact Us
          </NavBarItems>
        </NavBar>
      )}
      <HeaderContainer
        height={headerPosition ? "60px" : "100px"}
        fixed={headerPosition}
        background={headerPosition}
      >
        {headerPosition ? (
          <CustomNav onClick={handleShow} isHeight={true} />
        ) : (
          <>
            <HeaderTop>
              <DataWrapper width='200px'>
                <IconsWrapper target='_blank' href='#'>
                  <TwitterIcon />
                </IconsWrapper>
                <IconsWrapper
                  target='_blank'
                  href='https://www.facebook.com/Ago-Construction-104523781642120'
                >
                  <FacebookIcon />
                </IconsWrapper>
                <IconsWrapper target='_blank' href='#'>
                  <InstagramIcon />
                </IconsWrapper>
                <IconsWrapper target='_blank' href='#'>
                  <PinterestIcon />
                </IconsWrapper>
              </DataWrapper>
              <DataWrapper width='500px'>
                <InfoWrapper>
                  <GmailIcon />
                  <Span>agoconstruction77@gmail.com</Span>
                </InfoWrapper>
                <InfoWrapper>
                  <TelIcon />
                  <Span>+44 7405491104</Span>
                </InfoWrapper>
              </DataWrapper>
            </HeaderTop>
            <CustomNav onClick={handleShow} />
          </>
        )}
      </HeaderContainer>
    </>
  );
};

export default Header;
