import styled from "styled-components";
import { NavLink } from "react-router-dom";
import {
  SiTwitter,
  SiFacebook,
  SiInstagram,
  SiPinterest,
} from "react-icons/si";
import { MdEmail, MdPhone } from "react-icons/md";
import { FaTimes } from "react-icons/fa";

export const HeaderContainer = styled.header`
  height: ${({ height }) => (height ? height : "150px")};
  top: 0;
  left: 0;
  width: 100%;
  position: ${({ fixed }) => (fixed ? "fixed" : "absolute")};
  opacity: 1;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 98;

  @media screen and (max-width: 53rem) {
    height: 80px;
    position: fixed;
  }
`;

export const HeaderTop = styled.nav`
  height: 45%;
  width: 100%;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid white;
  background: #d97300;

  @media screen and (max-width: 53rem) {
    display: none;
  }
`;

export const DataWrapper = styled.div`
  width: ${({ width }) => (width ? width : "200px")};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconsWrapper = styled.a`
  text-decoration: none;
`;

export const FacebookIcon = styled(SiFacebook)`
  width: 20px;
  margin-top: 5px;
  height: 20px;
  fill: white;
  cursor: pointer;
`;

export const TwitterIcon = styled(SiTwitter)`
  width: 20px;
  margin-top: 5px;
  height: 20px;
  fill: white;
`;

export const InstagramIcon = styled(SiInstagram)`
  width: 20px;
  margin-top: 5px;
  height: 20px;
  fill: white;
`;

export const PinterestIcon = styled(SiPinterest)`
  width: 20px;
  margin-top: 5px;
  height: 20px;
  fill: white;
`;

export const InfoWrapper = styled.div`
  display: flex;
  width: auto;
  height: auto;
  align-items: center;
`;

export const GmailIcon = styled(MdEmail)`
  width: 16px;
  height: 16px;
  fill: white;
`;

export const TelIcon = styled(MdPhone)`
  width: 16px;
  height: 16px;
  fill: white;
`;

export const Span = styled.span`
  color: white;
`;

export const NavBar = styled.div`
  display: none;

  @keyframes top-bottom {
    from {
      top: -100%;
    }
    to {
      top: 0;
    }
  }

  @media screen and (max-width: 60rem) {
    width: 100%;
    height: 100vh;
    background: black;
    top: ${({ showNavbar }) => (showNavbar ? 0 : "-100%")};
    left: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    animation: top-bottom 0.3s ease-in-out;
  }
`;

export const TimesIcon = styled(FaTimes)`
  position: absolute;
  top: 50px;
  right: 50px;
  fill: white;
  height: 40px;
  width: 40px;
  transition: 0.3s ease-out;

  &:hover {
    width: 35px;
    height: 35px;
    fill: #e9e9e9;
  }
`;

export const NavBarItems = styled(NavLink)`
  color: white;
  font-size: 2rem;
  margin: 5px;
  padding: 5px 0;
  text-decoration: none;

  &:hover {
    color: #78aaff;
  }
`;
