import React from "react";
import image from "../../assets/billa.jpg";
import {
  Wrapper,
  FooterBottom,
  FooterContent,
  Rights,
  PartnersContainerWrapper,
  Heading,
  PartnersWrapper,
  PartnerLink,
  PartnerIcon,
  FooterContainer,
  ContactArea,
  HeadingLabel,
  ContactWithEmailOrPhone,
  Cont,
  TelContact,
  EmailContact,
  ContactLabel,
  AddressContainer,
  HomeAddress,
  AddressLabel,
  SocialMediaIconsWrapper,
} from "./Footer.elements";
import {
  Facebook,
  Twitter,
  Pinterest,
  Instagram,
} from "../SocialMediaIcons/SocialMedia";
import img from "../../assets/world.jpg";

const Footer = () => {
  const data = new Date();
  const year = data.getFullYear();

  return (
    <>
      <Wrapper>
        <FooterContent loading='lazy' src={img} alt='world image' />
        {/* <PartnersContainerWrapper>
          <Heading>Our Partners</Heading>
          <PartnersWrapper length={10}>
            <PartnerLink target='_blank' href='#'>
              <PartnerIcon src={image} />
            </PartnerLink>
            <PartnerLink target='_blank' href='#'>
              <PartnerIcon src={image} />
            </PartnerLink>
            <PartnerLink target='_blank' href='#'>
              <PartnerIcon src={image} />
            </PartnerLink>
            <PartnerLink target='_blank' href='#'>
              <PartnerIcon src={image} />
            </PartnerLink>
            <PartnerLink target='_blank' href='#'>
              <PartnerIcon src={image} />
            </PartnerLink>
            <PartnerLink target='_blank' href='#'>
              <PartnerIcon src={image} />
            </PartnerLink>
            <PartnerLink target='_blank' href='#'>
              <PartnerIcon src={image} />
            </PartnerLink>
            <PartnerLink target='_blank' href='#'>
              <PartnerIcon src={image} />
            </PartnerLink>
            <PartnerLink target='_blank' href='#'>
              <PartnerIcon src={image} />
            </PartnerLink>
            <PartnerLink target='_blank' href='#'>
              <PartnerIcon src={image} />
            </PartnerLink>
          </PartnersWrapper>
        </PartnersContainerWrapper> */}
        <FooterContainer>
          <ContactArea>
            <HeadingLabel justify='start' size='2rem'>
              Contact info
            </HeadingLabel>
            <ContactWithEmailOrPhone>
              <Cont>
                <TelContact />
                <ContactLabel>+44 7405491104</ContactLabel>
              </Cont>
              <Cont>
                <EmailContact />
                <ContactLabel>agocontruction77 @gmail.com</ContactLabel>
              </Cont>
            </ContactWithEmailOrPhone>
          </ContactArea>
          <ContactArea>
            <HeadingLabel justify='start' size='2rem'>
              Address
            </HeadingLabel>
            <ContactWithEmailOrPhone>
              <AddressContainer>
                <HomeAddress />
                <AddressLabel>
                  333 Marston Road, Marston, Oxford, Oxfordshire, United
                  Kingdom, OX3 0EP
                </AddressLabel>
              </AddressContainer>
            </ContactWithEmailOrPhone>
          </ContactArea>
          <ContactArea>
            <HeadingLabel justify='start' size='2rem'>
              Social Media
            </HeadingLabel>
            <SocialMediaIconsWrapper>
              <Twitter />
              <Instagram />
              <Pinterest />
              <Facebook />
            </SocialMediaIconsWrapper>
          </ContactArea>
        </FooterContainer>
      </Wrapper>
      <FooterBottom>
        <Rights>All rights reserved &copy; Ago Construction LTD {year}</Rights>
      </FooterBottom>
    </>
  );
};

export default Footer;
