import styled from "styled-components";
import { NavLink, Link as LinkR } from "react-router-dom";

export const LinkWrapper = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Hind&display=swap");
  position: relative;
`;

export const Link = styled(NavLink)`
  color: #cd3b04;
  font-size: 1rem;
  text-decoration: none;
  padding: 5px 5px 15px 5px;

  &.active {
    border-bottom: 3px solid #8cade1;
    color: #8cade1;
  }
`;

export const DropDown = styled.div`
  position: absolute;
  margin-top: 10px;
  width: auto;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  height: ${({ show }) => (show ? "auto" : "0")};
  background: white;
  border: 1px solid #ccc;
  transition: height 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;
  box-shadow: 0px 0px 3px 0px #ccc;
`;

export const DropDownLinks = styled(LinkR)`
  color: black;
  font-size: 1rem;
  text-decoration: none;
  font-family: "Hind", sans-serif;
  padding: 5px 25px;
  white-space: nowrap;

  &:hover {
    color: blue;
    background: #81a4ff;
    color: white;
  }
`;
