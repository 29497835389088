import styled from "styled-components";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

export const Nav = styled.nav`
  height: ${({ isHeight }) => (isHeight ? "100%" : "60%")};
  width: 100%;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 5px 0px #c0c0c0;
  background: ${({ isHeight }) => (isHeight ? "#FBFBFB" : "#FBFBFB")};
  transition: background 1s ease-in;

  @media screen and (max-width: 53rem) {
    height: 100%;
    background: #8cade1;
  }
`;

export const LogoWrapper = styled(Link)`
  width: auto;
  height: auto;
  padding: 5px;
`;

export const LinksWrapper = styled.div`
  width: 600px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 53rem) {
    display: none;
  }
`;

export const MenuIcon = styled(GiHamburgerMenu)`
  display: none;

  @media screen and (max-width: 53rem) {
    display: block;
    width: 30px;
    height: 30px;
    color: white;
    cursor: pointer;

    &:hover {
      color: black;
    }
  }
`;
