export const linksList = [
  {
    label: "Home",
    to: "/",
    isExact: true,
  },
  {
    label: "About",
    to: "/about",
    isExact: false,
  },
  {
    label: "Services",
    to: "/services",
    isExact: false,
    isDropDown: true,
    dropDownElements: [
      {
        id: 1,
        to: "house-renovation",
        label: "House Renovation",
      },
      {
        id: 2,
        to: "house-renovation",
        label: "House Renovation",
      },
      {
        id: 3,
        to: "house-renovation",
        label: "House Renovation",
      },
    ],
  },
  {
    label: "Customer Projects",
    to: "/customer-projects",
    isExact: false,
    isDropDown: false,
    dropDownElements: [
      {
        id: 1,
        to: "house-renovation",
        label: "New Build & House Renovation",
      },
      {
        id: 2,
        to: "conservatories-extensions",
        label: "Conservatories, Extensions & Loft Conversions",
      },
      {
        id: 3,
        to: "carpentry-services",
        label: "Carpentry Services",
      },
      {
        id: 4,
        to: "plastering-services",
        label: "Plastering Services",
      },
    ],
  },
  {
    label: "Contact Us",
    to: "/contact",
    isExact: false,
  },
];
