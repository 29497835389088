import React, { lazy, Suspense } from "react";
import "./App.css";
import Header from "./components/Header/Header.component";
import Footer from "./components/Footer/Footer";
import { Route, Switch } from "react-router-dom";
import Sniper from "./components/Sniper/Sniper";

const MainPage = lazy(() => import("./pages/main/Main.page"));
const AboutPage = lazy(() => import("./pages/about/About.page"));
const ContactUsPage = lazy(() => import("./pages/contact/Contact"));
const CustomerPage = lazy(() => import("./pages/customers/CustomerPage"));
const ServicesPage = lazy(() => import("./pages/services/ServicesPage"));

const App = (props) => {
  return (
    <>
      <Header />
      <Switch>
        <Suspense fallback={<Sniper />}>
          <Route exact path='/' component={MainPage} />
          <Route path='/about' component={AboutPage} />
          <Route path='/services' component={ServicesPage} />
          <Route path='/contact' component={ContactUsPage} />
          <Route path='/customer-projects' component={CustomerPage} />
        </Suspense>
      </Switch>
      <Footer />
    </>
  );
};

export default App;
