import React from "react";

import { Nav, LogoWrapper, LinksWrapper, MenuIcon } from "./Nav.elements";

import Logo from "../Logo/Logo";
import CustomLink from "../Links/CustomLink";

import { linksList } from "./data";

const CustomNav = ({ onClick, isHeight, styles }) => {
  return (
    <Nav isHeight={isHeight}>
      <LogoWrapper to='/'>
        <Logo />
      </LogoWrapper>
      <LinksWrapper>
        {linksList.map(({ ...props }, index) => (
          <CustomLink key={index} {...props} />
        ))}
      </LinksWrapper>
      <MenuIcon onClick={onClick} />
    </Nav>
  );
};

export default CustomNav;
