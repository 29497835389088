import styled, { css } from "styled-components";
import image from "../../assets/world.jpg";
import { MdEmail, MdPhone, MdHome } from "react-icons/md";

export const Wrapper = styled.div`
  height: 200px;
  background: #000;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 39rem) {
    height: 400px;
  }
`;

export const FooterContent = styled.img`
  width: 100%;
  max-width: 100%;
  // background: url(${image});
  // background-size: cover;
  opacity: 0.2;
`;

export const PartnersContainerWrapper = styled.div`
  width: 100%;
  height: 50%;
  border-bottom: 1px solid white;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 0;

  @media screen and (max-width: 39rem) {
    height: 30%;
  }
`;

export const Heading = styled.h2`
  color: white;
  font-family: "Roboto", sans-serif;
  text-align: center;
  position: relative;
  height: 25%;

  &::before,
  &::after {
    content: "";
    width: 45%;
    height: 1px;
    background: white;
    display: block;
    position: absolute;
    bottom: 0;
  }

  &::before {
    right: 0;
    bottom: 0;
  }

  &::after {
    left: 0;
  }
`;

export const PartnersWrapper = styled.div`
  height: 75%;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;
  padding: 10px;
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(${({ length }) => length}, 10%);
  grid-gap: 15px;

  @media screen and (max-width: 54rem) {
    grid-template-columns: repeat(${({ length }) => length}, 20%);
  }

  @media screen and (max-width: 39rem) {
    grid-template-columns: repeat(${({ length }) => length}, 30%);
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PartnerLink = styled.a`
  text-decoration: none;
  margin: 0;
  padding: 0;
`;

export const PartnerIcon = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  transition: opacity 0.3s linear;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 5px;

  @media screen and (max-width: 39rem) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
`;

const area = css`
  width: 100%;
  height: 100%;
`;

export const CompanyNameArea = styled.div`
  ${area}
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const HeadingLabel = styled.h1`
  color: #f35c27;
  font-size: ${({ size }) => size};

  @media screen and (max-width: 47rem) {
    font-size: 1.5rem;
  }
`;

export const ContactArea = styled.div`
  ${area}
  display: flex;
  flex-direction: column;
  padding: 15px;

  @media screen and (max-width: 39rem) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ContactWithEmailOrPhone = styled.div`
  display: flex;
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 39rem) {
    align-items: center;
    justify-content: center;
  }
`;
export const Container = styled.div`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

export const Cont = styled.div`
  display: flex;
  height: 50%;
  align-items: center;
`;

export const AddressContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 20% 1fr;
`;

const icons = css`
  width: 20px;
  height: 20px;
  fill: white;
`;

export const TelContact = styled(MdPhone)`
  ${icons}
`;

export const EmailContact = styled(MdEmail)`
  ${icons}
`;

export const HomeAddress = styled(MdHome)`
  width: 50px;
  height: 50px;
  color: white;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 1;
  justify-self: center;
  align-self: center;

  @media screen and (max-width: 57rem) {
    width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 39rem) {
    width: 50px;
    height: 50px;
  }
`;

export const AddressLabel = styled.span`
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 3;
  justify-self: center;
  align-self: center;
  color: white;
  margin: 0 5px;

  @media screen and (max-width: 43rem) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 39rem) {
    font-size: 1rem;
  }
`;

export const ContactLabel = styled.span`
  color: white;
`;

export const SocialMediaIconsWrapper = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 55rem) {
    width: 100%;
  }

  @media screen and (max-width: 39rem) {
    width: 60%;
  }
`;

export const FooterBottom = styled.div`
  width: 100%;
  height: 45px;
  background: #000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 50px;

  @media screen and (max-width: 39rem) {
    padding: 0 50px;
    padding: 0;
  }
`;

export const Rights = styled.h4`
  font-family: "Roboto", sans-serif;
  color: white;
  margin: 0 auto;
  font-size: min(1rem, 90vw);
  text-align: center;
`;
